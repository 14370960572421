<template>
  <v-col cols="12">
    <v-row>
      <v-col cols="12">
        <data-table
          :headers="headers"
          :items="obCollection.getModelList()"
          :last-page="serverLastPage"
          :loading="bLocalLoading"
          :options="pagination"
          :total="serverItemsLength"
          btn-action-item-key-id="id"
          btn-action-path="products"
          hide-btn-action-view
          @delete="deleteItem"
          @update:sort-by="onSortBy"
          @update:sort-desc="onSortDesc"
          @update:options="onPagination"
          @update:page="onPage"
        >
          <template #[`body.prepend`]="{ headers }">
            <product-filters-row :tr-headers="headers" />
          </template>

          <template v-slot:[`item.active`]="{ item }">
            <active-icon :active="item.active" />
          </template>

          <template #[`item.name`]="{ item }">
            <name-with-avatar
              :name="item.name"
              :path="item.thumbnail"
              :to="{ name: 'products.update', params: { id: item.id } }"
            />
          </template>

          <template #[`item.category`]="{ item }">
            <category-view-name :id="item.category_id"></category-view-name>
          </template>

          <template #[`item.offer`]="{ item }">
            <price-dialog :product="item" />
          </template>

          <template #[`item.actions`]="{ item }">
            <module-actions :value="item.id" @delete="deleteItem" />
          </template>
        </data-table>
      </v-col>
    </v-row>
    <products-form
      :key="$route.params.id"
      v-model="obItem"
      :open.sync="displayForm"
    />
  </v-col>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import ProductsMixin from "@/modules/products/mixins/ProductsMixin";
import type { DataTableHeader } from "vuetify";
import Utils from "@/services/Utils";

import ActiveIcon from "@/components/common/ActiveIcon.vue";
import ProductsForm from "@/modules/products/components/Form.vue";
import NameWithAvatar from "@/components/common/NameWithAvatar.vue";
import CategoryViewName from "@/modules/categories/components/ViewName.vue";
import PriceDialog from "@/modules/products/components/PriceDialog.vue";
import type { Product } from "@planetadeleste/vue-mc-shopaholic";
import { set } from "lodash";
import ProductFiltersRow from "@/modules/products/components/ProductFiltersRow.vue";

@Component({
  components: {
    ProductFiltersRow,
    ActiveIcon,
    ProductsForm,
    NameWithAvatar,
    CategoryViewName,
    PriceDialog,
  },
})
export default class ProductsList extends Mixins(ProductsMixin) {
  isList = true;

  onMounted() {
    const arHeaders: DataTableHeader[] = [
      {
        text: "code",
        value: "code",
        sortable: true,
      },
      {
        text: "category",
        value: "category",
        sortable: true,
      },
      {
        text: "price",
        value: "offer",
        sortable: false,
      },
    ];
    this.addDTHeaders(arHeaders);
    // this.index();
  }

  onBeforeIndex(obFilters: Record<string, any>) {
    set(obFilters, "byNotGenerated", 1);
  }

  async onLoadIndex(sValue: string) {
    if (sValue !== this.sModelName) {
      return;
    }

    /*
    if (!has(this.modelFilters, "pricelist")) {
      const obPriceListCollection = new PriceListCollection();
      obPriceListCollection.filterBy({ defaults: 1 });
      await obPriceListCollection.fetch();

      if (obPriceListCollection.length) {
        const obPriceList = obPriceListCollection.first();
        if (obPriceList) {
          this.onSetFilters({ pricelist: obPriceList.id });
        }
      }
    }
*/

    this.index();
  }

  onRegisterEvents() {
    this.addEvent("app.branch.selected", this.index);
    this.addEvent("filters.change", this.index);
    this.addEvent("before.show.form", this.getCode);
    this.addEvent("model.mounted", this.onLoadIndex);
  }

  getCode(obItem: Product) {
    if (obItem.id || obItem.code) {
      return;
    }

    const obProductGw = obItem.get("product_gw", {});
    if (obProductGw) {
      set(obProductGw, "is_sell", true);
      obItem.set("product_gw", obProductGw);
    }

    Utils.nextProductCode().then((response) => {
      this.obItem.set("code", response.data);
    });
  }
}
</script>
